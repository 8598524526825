//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Shpere from "@/components/3d/sphere";
export default {
  components: {
    Shpere,
  },
  data() {
    return {
      action: {
        bgShow: false,
        titleShow: false,
      },
    };
  },
  mounted() {
    this.action.bgShow = true;
    this.action.titleShow = true;
    // particlesJS.load("particles", "/particles.json");
  },
  methods: {
    //====================动画
    sphereAnime(el, done) {
      this.anime({
        targets: el,
        scale: [0.5, 1],
        opacity: [0, 1],
        easing: "linear",
        duration: 1000,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    engAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [400, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    subTitleAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    titleAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    //====================动画
  },
};
